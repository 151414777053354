import Cookies from "universal-cookie";
import { connectWallet } from "./helper/wallet-helper";
import { getHostContract } from "./helper/contract-helper";
import { deleteDatabase } from "./db/db-helper";
const cookies = new Cookies();

export function requireAuth() {

    const user = cookies.get("userObject") ;
    if(user && user.name){
        connectWallet().then(async (address)=> {
            if(address){
                const contract = getHostContract();
                const getUserDetailsForWallet = await contract.methods.getUserDetailsForWallet().call({ from: address });

                if(getUserDetailsForWallet && getUserDetailsForWallet.length){

                    const userName = user.name;
                    const userPresent = getUserDetailsForWallet.some(userData => userData.domain === userName);

                    if(!userPresent){
                        const userFirstData = getUserDetailsForWallet[0];
    
                        cookies.set("userObject", '', { path: "/", maxAge: 0 });
                        cookies.set("accessToken", '', { path: "/", maxAge: 0 });
                    
                        const userObject = { name : userFirstData.domain ,  wallet :  userFirstData.walletAddress , publicKey :  userFirstData.publicKey  };
                        cookies.set("userObject", userObject, { path: "/" });

                        deleteDatabase();
                        window.open('/inbox','_self');
                    }else{                        
                        // TODO
                    }
                }else{
                    deleteDatabase();
                    window.open('/','_self');
                }
            }else{
                window.open('/','_self');
            }        
        })
        return true;
    }

    window.open('/','_self');
}
  
