import Web3 from 'web3';
import { transactionAction } from './chain-helper';
export const web3AccountCheck = async (setWeb3 , setAccount) => {

  if (window.ethereum) {
    const web3Instance = new Web3(window.ethereum);
    setWeb3(web3Instance);

    // Check if user is already connected
    window.ethereum
      .request({ method: 'eth_accounts' })
      .then(accounts => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        }
      })
      .catch(err => console.error(err));

    // Listen for account changes
    window.ethereum.on('accountsChanged', accounts => {
      setAccount(accounts[0] || '');
    });
  } else {
    console.log('MetaMask is not installed');
  }

} 

export const getPublicKeyValue = async () => {

  try {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });

      if (accounts.length) {
          const encryptionPublicKey = await window.ethereum.request({
              method: 'eth_getEncryptionPublicKey',
              params: [accounts[0]]
          })
          return encryptionPublicKey;
      }
      return null;            
  } catch (error) {
      return null;
  }
}


export const markMessageAsImportant = async (contract, userName , messageObject , account) => {
  try {
    const functionParams = [userName, [messageObject.id], !messageObject.isStarred];
    await transactionAction(contract, "updateEmailsToStar", functionParams, account);        
    return true;
  } catch (error) {
    console.error(error)
    return false;
  }

}


export const getAccountBalance = async (web3, account) => {
  const balance = await web3.eth.getBalance(account);
  return web3.utils.fromWei(balance, 'ether');
}