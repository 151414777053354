import React, { useState } from 'react';
import { copyEmail } from '../../helper/email-helper';
import Cookies from "universal-cookie";
import { folders } from '../../assets/svg/folder-svg';
import { deleteEmails, updateAttribute } from '../../helper/emailHelper.js';

const cookies = new Cookies();

function getColorFromJson(jsonString) {

    try {
        const json = jsonString;
        const color = json.folderProperties.find(value => value.key == 'color')['value']
        return color;
    } catch (error) {
        return "green";
    }

}

const ContextMenu = ({ x, y, onClose, types, selectedContext, loader, pageType, reRender }) => {
    const [visible, setVisible] = useState(true); // State to manage visibility of the context menu
    const [user] = useState(cookies.get("userObject"));
    const isArchive = ["Important", "Inbox", "Sent"].includes(pageType);

    // Function to handle click outside of the context menu
    const handleClickOutside = (event) => {
        if (!event.target.closest('.contextMenu')) {
            onClose(); // Close the context menu
        }
    };

    // Attach click event listener to handle clicks outside of the context menu
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Style object to position the context menu
    const style = {
        position: 'fixed',
        left: x + 'px',
        top: y + 'px',
        background: 'white'
    };

    // Function to handle context menu option click
    const handleOptionClick = async (folderName) => {
        const type = pageType ==='Sent' ? `sent-folder-${parseInt(folderName.id)}` : `inbox-folder-${parseInt(folderName.id)}`;
        loader(true);
        // await copyEmail(selectedContext, folderName, user.name);
        await updateAttribute(user.name, selectedContext.id, type, 'folder', true);
        loader(false);
        onClose(); // Close the context menu
    };

    const handleRestore = async () => {
        loader(true);
        await deleteEmails(selectedContext, null, null, null, selectedContext.emailType, null);
        loader(false);
        reRender();
        onClose();
    };

    const handleArchive = async (value) => {
        loader(true);
        await updateAttribute(user.name, selectedContext.id, 'isArchive', 'email', value);
        loader(false);
        reRender();
        onClose();
    };

    return (
        visible && (
            <div className="contextMenu copying-content" style={style}>
                <div className="contextMenu--option-div" onClick={onClose}>
                    {types.length > 0 && pageType !== 'Trash' && pageType !== 'Archive' && types.map((item, index) => (
                        <>
                            <div key={index} className="contextMenu--option cursor-pointer" onClick={() => handleOptionClick(item)}>
                                <div className="contextMenu--option-div-line"> {folders.folderIcon[`${getColorFromJson(item)}Folder`]} </div>
                                <div> Move to {item.name} </div>
                            </div>
                        </>
                    ))}
                    {pageType === 'Trash' &&
                        <>
                            <div className="contextMenu--option cursor-pointer" onClick={() => handleRestore()}>
                                <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                <div> Restore</div>
                            </div>
                        </>
                    }
                    {pageType === 'Archive' &&
                        <>
                            <div className="contextMenu--option cursor-pointer" onClick={() => handleArchive(false)}>
                                <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                <div>Move to inbox</div>
                            </div>
                        </>
                    }
                    {isArchive &&
                        <>
                            <div className="contextMenu--option cursor-pointer" onClick={() => handleArchive(true)}>
                                <div className="contextMenu--option-div-line">{folders.folderIcon[`greenFolder`]}</div>
                                <div>Move to Archive List</div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    );
};

export default ContextMenu;
