import Web3 from 'web3';
import hostABI from '../web3/hostContract.json';
import helperContract from '../web3/helperContract.json';
import config from '../web3/web3Config.json';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const web3 = new Web3(window.ethereum);

export const getHostContract = () => {
    const hostContractMethod = new web3.eth.Contract(hostABI.contract, config.CONTRACT);
    return hostContractMethod;
}

export const getHostHelperContract = () => {
    const helperContractMethod = new web3.eth.Contract(helperContract.contract, config.HELPER);
    return helperContractMethod;
}


export const getUserDetails = () => {
    try {        
        const user = cookies.get("userObject");
        return user;
    } catch (error) {
        return null;
    }
}