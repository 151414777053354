import Web3 from 'web3';
import contractData from '../web3/hostContract.json';
import hostRegistry from '../web3/hostRegistry.json';
import config from '../web3/web3Config.json';
import Cookies from "universal-cookie";
import { getPublicKey , sendEmailOnDifferentChain, deleteFolderMessage} from './email-helper.js';
import { getEncryptedValue, sendAttachment, sendWebTwoEmail, updateInsecureMail } from '../service/api-actions';
import { transactionAction } from './chain-helper';
import db from '../db/db-service.js';
import { getHostHelperContract, getHostContract } from './contract-helper.js';
import { returnStringJson } from '../helper/object-validation-helper';
import { toast, Bounce } from 'react-toastify';

const contractAddress = config.CONTRACT;
const chainId = config.CHAIN_ID;
const cookies = new Cookies();

const web3 = new Web3(window.ethereum);
const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);




export async function saveSenderEncryptedEmail(emailObject) { // Save sent items.
    const userName = cookies.get("userObject");
    const msg = JSON.stringify(emailObject);
    const publicKey = await contractMethods.methods.getPublicKeyOfUser(userName.name).call();
    const data = await getEncryptedValue(msg, publicKey);
    const encryptedMessage = data.returnValue;

    return encryptedMessage;
  }


  const validateEmails = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emails.filter(email => !emailRegex.test(email));
    return {
        isValid: invalidEmails.length === 0,
        invalidEmails
    };
  };

// function to send/store email on blockchain
export const sendEmails = async (to, cc, bcc, subject, message  , isSavedOn=false , defaultEncryptedMessage="MSG" , files=null) => {

    const allUsers = to.concat(cc, bcc).filter(value => value !== "");
    if(allUsers.length === 0) {
        toast.error("Please specify at least one recipient.", {
            position: "top-center",
            transition: Bounce,
        });
        return false;
    }
    const { isValid, invalidEmails } = validateEmails(allUsers);
    if (!isValid) {
        const errorMessage = `Invalid email ${invalidEmails.length > 1 ? 'addresses' : 'address' }: ${invalidEmails.join(", ")}`;
        toast.error(errorMessage, {
            position: "top-center",
            transition: Bounce,
        });
        return false;
    }
    const unIdentifiedUser = await userAvailableCheck(allUsers);

    const userNameData = cookies.get("userObject");
        const outbox = {
            to: to,
            bcc: bcc,
            cc: cc,
            subject: subject,
            message: message,
            sender: userNameData.name,
        }
        try {
        const UTCtime = new Date().toUTCString();
        // to encrypt the message.
        const [toEncryptionMessage, ccEncryptionMessage, bccEncryptionMessage] = await Promise.all([
            await getEncryptedMessageByUserArray(to, subject, message , files),
            await getEncryptedMessageByUserArray(cc, subject, message , files),
            await getEncryptedMessageByUserArray(bcc, subject, message , files)
        ]);

        const accounts = await window.ethereum. request({ method: 'eth_accounts' });
        const sender = userNameData.name;
        const emailHeaderObject = { sender, to, cc, bcc,subject};
        const emailDetails = [accounts[0], UTCtime, JSON.stringify(emailHeaderObject) , defaultEncryptedMessage ];
        const functionParams = [subject, sender, to, cc, bcc, toEncryptionMessage, ccEncryptionMessage, bccEncryptionMessage, emailDetails];
        let filteredTo = functionParams[2].filter((val, index) => functionParams[5][index] !== '-');
        let filteredCC = functionParams[3].filter((val, index) => functionParams[6][index] !== '-');
        let filteredBcc = functionParams[4].filter((val, index) => functionParams[7][index] !== '-');
        let filterEncryptTo = functionParams[5].filter(val => val !== '-');
        let filterEncryptCC = functionParams[6].filter(val => val !== '-');
        let filterEncryptBcc = functionParams[7].filter(val => val !== '-');

        const isOwnDomainPresent = await getOwnDomainBoolStatus([filteredTo, filteredCC, filteredBcc]);
        const isChainDomainPresent = await getChainDomainJson([filteredTo, filteredCC, filteredBcc]); // TODO

        const filteredParams = [subject, sender, filteredTo, filteredCC, filteredBcc, filterEncryptTo, filterEncryptCC, filterEncryptBcc, emailDetails];
        if(unIdentifiedUser.concat(filteredTo.concat(filteredCC, filteredBcc)).length !== to.concat(cc, bcc).length){
            toast.error("Please enter a valid email address", {
                position: "top-center",
                transition: Bounce,
            });
            return false;
        }
        let checkIsSaved = false;
        if(isChainDomainPresent.length){
            checkIsSaved = true;
            for(let chainData of isChainDomainPresent){
                const chainHostContract = chainData[0];            
                const chainHostJson = JSON.parse(chainData[1]);
                if(chainHostJson.chainId === parseInt(chainId)){
                    const contractMethodValue = new web3.eth.Contract(contractData.contract, chainHostContract);
                    const returnValue = await transactionAction(contractMethodValue, "sendEmailValues", filteredParams, accounts[0]); 
                    if(!returnValue){
                        await setOutBoxDB(outbox);
                    } 
                }else{
                    const chainJson = await getChainDetails(config.DOMAIN);
                    const returnValue = await sendEmailOnDifferentChain(filteredParams , chainJson , accounts[0] , chainData);
                    if(!returnValue){
                        await setOutBoxDB(outbox);
                    } 
                }
            }
        }

        if (isOwnDomainPresent) {
            checkIsSaved = true;
            const returnValue = await saveEmailForUser(accounts[0], filteredParams); // send emails
            if(!returnValue){
                await setOutBoxDB(outbox);
            } 
        }

        for(const web2 of unIdentifiedUser){
            const status = await domainAvailableCheck(web2.split("@")[1]);
            const web2Object = [subject, userNameData.name, to, cc, bcc, message , files];
            const helperContractMethod = getHostHelperContract();
            
            if(!status){
                checkIsSaved = true;
                const  helperfunctionParams = [userNameData.name , unIdentifiedUser] ;
                const web2AddressBookForUser = await helperContractMethod.methods.getWeb2Emails(userNameData.name).call({ from: userNameData.wallet });  
                const filterAddress = web2AddressBookForUser.filter(email => email === web2);
                if(filterAddress.length === 0){
                    await transactionAction(helperContractMethod, "addWeb2Emails", helperfunctionParams, accounts[0]); 
                }
                await sendWebTwoEmail(web2Object);
                break ;
            }
        }
        if(isSavedOn && checkIsSaved) {
            const functionParams = [subject, userNameData.name, emailDetails];
           await transactionAction(contractMethods, "setSavedEmail", functionParams, accounts[0]); 
        }
        if(!checkIsSaved){
            toast.error("Something went wrong!", {
                position: "top-center",
                transition: Bounce,
            });
            return false;
        }
        return true;
    } catch (error) {
        console.error('error',error);
        await setOutBoxDB(outbox);
    }
}

async function setOutBoxDB(json) {
    const decryptedObject = { json: json };
    const value = await db.table('outbox').add(decryptedObject);
    return value;
}

async function getEncryptedMessageByUserArray(userArray, subject, message , files) {

    let returnArray = [];
    for (let i = 0; i < userArray.length; i++) {
        const user = userArray[i];
        if(user){
            const encryptedMessage = await getEncryptedMessageByUser(user, subject, message , files);
            returnArray.push(encryptedMessage || "-");
        }
    }
    return returnArray;
}


async function getEncryptedMessageByUser(user, subject, message , files) {


    const receiptDomain = user.split("@")[1];
    const domain = await contractMethods.methods.constDomain().call();
    const isSameHost = (receiptDomain === domain);
    const obj = { recipient: user };

    
    const retrivedAddress = await getChainDetails(receiptDomain);
    let contactAddressFromName = null, jsonValue = null, receiptentChainId = null;

    if (retrivedAddress["0"] && retrivedAddress["1"]) {
        contactAddressFromName = retrivedAddress["0"];
        jsonValue = JSON.parse(retrivedAddress["1"]);
        receiptentChainId = jsonValue.chainId;
    }
    const publicKey = await getPublicKey(obj, isSameHost, contactAddressFromName, jsonValue);
    const emailObject = { recipient: user, subject: subject, message: message };

    if (!publicKey) return null;

    if(files && files.length){  
        const attachmentResult = await sendAttachment(files , publicKey);
        emailObject.attachment = attachmentResult.data.returnValue;
    }

    const msg = JSON.stringify(emailObject);
    const data = await getEncryptedValue(msg, publicKey);

    return data && data.returnValue;
}

async function saveEmailForUser(account, functionParams) {
    const contractMethodss = new web3.eth.Contract(contractData.contract, contractAddress);
    const txHash = await transactionAction(contractMethodss, "sendEmailValues", functionParams, account);
    return txHash;
}

async function getChainDetails(receiptDomain) {
    const hostAddress = await contractMethods.methods.hostRegistryAddress().call();
    const hostContractMethods = new web3.eth.Contract(hostRegistry.contract, hostAddress);
    const chainJson = await hostContractMethods.methods.getChainDetails(receiptDomain).call();
    return chainJson;
}

async function getOwnDomainBoolStatus(userArray) {

    let present = false;
    const domain = await contractMethods.methods.constDomain().call();

    for (const users of userArray) {
        for (const user of users) {
            if (user.includes(domain)) {
                present = true;
                break;
            }
        }
    }

    return present;
}

async function getChainDomainJson(userArray) {

    const hostDetails = [];
    const domain = await contractMethods.methods.constDomain().call();

    for (const users of userArray) {
        for (const user of users) {
            const userDomain = user.split("@")[1];
            if (userDomain && userDomain != domain) {
                const chainJson = await getChainDetails(userDomain);
                if (chainJson["0"] && chainJson["1"]) {
                    chainJson["user_domain"] = userDomain;
                    hostDetails.push(chainJson);
                }
            }
        }
    }

    return hostDetails;
}

async function domainAvailableCheck(userDomain){

    const chainJson = await getChainDetails(userDomain);
    if (chainJson["0"] && chainJson["1"]) {
        return true;
    }

    return false;
}


async function userAvailableCheck(userList){


    const unIdentifiedUser = [];
    for(let user of userList){
        const userDomain = user.split("@")[1];   
    
        const chainJson = await getChainDetails(userDomain);
        if (chainJson["0"] && chainJson["1"]) {
            const jsonValue = JSON.parse(chainJson["1"]);

            const web3Data = new Web3(jsonValue.rpc_url);
            const contractMethodsData = new web3Data.eth.Contract(contractData.contract, jsonValue.contractAddress);
            const publicKey = await contractMethodsData.methods.getPublicKeyOfUser(user).call();

            // if(!publicKey){
            //     unIdentifiedUser.push(user);
            // }
        }else{
            unIdentifiedUser.push(user);
        }    

    }

    return unIdentifiedUser
}

export const deleteEmails = async(message, msgId, index, messageList, type, getFolderIndex) => {
    const user = cookies.get("userObject");
    switch(type){
        case "Draft":
            await deleteDraft(user.name, msgId);
            break;
        case "Outbox":
            await deleteOutbox(index);
            break;
        case "Insecure":
            await deleteInsecure(msgId);
            break;
        case "Trash":
            await deleteTrashMail(user.name, msgId, message);
            break;
        case "Sent":
            await deleteSentMail(user.name, msgId);
            break;
        case "Folders":
            await deleteFolderMail(index, message, user.name, user.wallet, getFolderIndex);
            break;
        case "Inbox":
        case "Archive":
        case "Important":
            await deleteInboxMail(user.name, msgId);
        case "inboxtrash":
        case "Senttrash":
            await restoreEmail(user.name, message, type);
            break;
        case "emptyTrash":
            await emptyTrash(message, user.name);
            break;
        default:
            break;
    }
}
async function emptyTrash(message, userName){
    const inboxData = message.filter(value => value.emailType === 'inboxtrash').map(value => parseInt(value.id));
    const sentData = message.filter(value => value.emailType === 'Senttrash').map(value => parseInt(value.id));
    const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
    const contractMethods = getHostContract();
    const collection = [
         inboxData,
         sentData
        ];
    
    const functionParams = [ userName , collection];
    await transactionAction(contractMethods, "emptyTrash" , functionParams, accounts[0]);
}

async function restoreEmail(userName, message, type){
    if(type === 'inboxtrash'){
        const functionParams = [ userName , [parseInt(message.id)], false];
        const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
        const contractMethods = getHostContract();   
        await transactionAction(contractMethods, "saveTrashList" , functionParams, accounts[0]);
    }else if(type === 'Senttrash'){
        const functionParams = [ userName , [parseInt(message.id)], false];
        const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
        const contractMethods = getHostContract();
        await transactionAction(contractMethods, "deleteSentEmailsById" , functionParams, accounts[0]);
    }
}

async function deleteDraft(userName, id){
    await db.table('draft').delete(id);
}

async function deleteOutbox(index){
    const indexdb = await db.table('outbox').toArray();
    if (indexdb.length > 0) {
        const getid = indexdb[index];
        await db.table('outbox').delete(getid.id);
    }
}

async function deleteInsecure(id){
    await updateInsecureMail('delete', id);
}

const deleteTrashMail = async(userName, msgId, message) => {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
    const contractMethods = getHostContract();
    if(message.emailType && message.emailType === 'Senttrash'){
        const functionParams = [ userName , [parseInt(msgId)]];
        await transactionAction(contractMethods, "deletePermenentSentEmailsById" , functionParams, accounts[0]);
    }else{
        const functionParams = [ userName , [parseInt(msgId)]];
        await transactionAction(contractMethods, "permanentlyDeleteEmail" , functionParams, accounts[0]);
    }
}

const deleteSentMail = async(userName, msgId) => {
    const functionParams = [ userName , [parseInt(msgId)], true];
    const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
    const contractMethods = getHostContract();
    await transactionAction(contractMethods, "deleteSentEmailsById" , functionParams, accounts[0]);
}

const deleteFolderMail = async(index, messageList, userName, wallet, getFolderIndex) => {
    const messageType = messageList.emailType;
    const msgId = messageList.id;
    if(messageType == 'inbox'){
        await deleteInboxMail(userName, msgId);
    }else if(messageType == 'sent'){
        await deleteSentMail(userName, msgId);
    }
}

const deleteInboxMail = async(userName, msgId) => {
    const functionParams = [ userName , [parseInt(msgId)], true];
    const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
    const contractMethods = getHostContract();   
    await transactionAction(contractMethods, "saveTrashList" , functionParams, accounts[0]);
}

export const updateAttribute = async (userName, id, key, type, value) => {
    const functionParams = [ userName , parseInt(id), type, key, value];
    const accounts = await window.ethereum.request({ method: 'eth_accounts' }); 
    const helperContractMethod = getHostHelperContract();
    await transactionAction(helperContractMethod, "setAttribute" , functionParams, accounts[0]);
}