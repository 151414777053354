import React, { useState } from 'react';
import { logo } from '../assets/svg/svg'
import { Link } from 'react-router-dom'
import '../components/styles/settings.css'
import { useSelector } from 'react-redux';
import ComposeEmail from '../components/emails/ComposeEmail.js';
import  General  from '../components/settings/General.js';
import  Profile  from '../components/settings/Profile.js';
import  FilterEmail  from '../components/settings/FilterEmail.js';

const settingsTitle = [
    { label: "General Settings", id: 1 },
    { label: "Profile Settings", id: 2 },
    { label: "Blocked Addresses", id: 3 }
]


const Settings = () => {
    const [isCompose , setIsCompose ] = useState(false);
    const [receiverNameValue, setReceiverName] = useState("");
    const [activeTitle, setActiveTitle] = useState(1);

    const getCompose = useSelector((state) => state.isCompose);
      React.useEffect(() => {
        setIsCompose(getCompose);
    }, [getCompose]);


    const reRender = () => {
        //
    }

  return (
    <div>
        <div className='box-common-header web3-id'> 
          <div className='box-common-header-back web3-id' onClick={()=> {""}}>
                  <Link to="/inbox">  {logo.msg_box_back_btn}  </Link>
          </div>
          <div className='web3-id-header-name'>  Settings </div>
        </div>   
        <div className='container padding-30'>
            <div className='row gap-10 d-flex'>
                <div className='col-3 settings-catagery-section'>
                {settingsTitle.map((title) => (
                        <li className={"list-group-item cursor-pointer border-bottom setting-list" + `${activeTitle === title.id ? ' gradient-text' : ''}`} onClick={() => { setActiveTitle(title.id) }}>{title.label}</li>
                    )) 
                }
                </div>
                <div className='col-7 settings-catagery-content border-start'>
                    {activeTitle === 1 ? <General /> : null}
                    {activeTitle === 2 ? <Profile /> : null}
                    {activeTitle === 3 ? <FilterEmail /> : null}
                </div>
            </div>
        </div>
            {isCompose && 
                <div > 
                    <ComposeEmail setIsCompose={setIsCompose} reRender={reRender()} receiverName={receiverNameValue} pageType={'Settings'}/>
                </div>
            }   
    </div>
  )
}

export default Settings
