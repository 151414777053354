import { getDecryptedContentFromIndexedDb } from "../db/indexed-helper";
import { getBlockedList } from '../helper/settingsHelper.js';

export const formatMessageList = async (messageList, type="no_data") => {
    const allList = [];
    const readList = [];
    const unReadList = [];
    const deletedList = [];
    const getBlocked = await getBlockedList();
    const blockedAddresses = getBlocked.address ? getBlocked.address.split(',') : [];

    for (let msg of messageList) {
        const idformat = type === 'Insecure' ? msg.id : parseInt(msg.id);
        const decryptedMessage = await getDecryptedContentFromIndexedDb(idformat , type.toLowerCase());
        if(msg){
            if(blockedAddresses.includes(msg.sender)){
                continue;
            }
            const returnObject = {
                messageId: idformat,
                subject: msg.subject,
                encryptedMsg: msg.encryptedData,
                date: msg.receivedDate,
                emailHeader: msg.emailDetails,
                read: msg.isRead,
                important: msg.isStarred,
                bcc: msg.isBCC,
                cc: msg.isCC , 
                sender : msg.senderName ,
                decryptedMessage : decryptedMessage ,
                ... msg
            };
            if(type === 'Trash'){
                returnObject.emailType = 'inboxtrash';

            }else if(type === 'Senttrash'){
                returnObject.emailType = 'Senttrash';
                returnObject.isPermanentDeleted = msg.isPermanentDeleted;
            }

            if (msg && !msg.isDeleted) {
                allList.push(returnObject);
                if (msg.isRead) {
                    readList.push(returnObject);
                } else {
                    unReadList.push(returnObject);
                }
            }else{
                deletedList.push(returnObject);
            }
        }
    }

    return { allList, readList, unReadList , deletedList};
}
