import React, { useEffect } from "react";
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import Cookies from "universal-cookie";
import Web3 from 'web3';
import { toast, Bounce } from 'react-toastify';
import { getInsecure } from "../service/api-actions";
import { useSelector, useDispatch } from "react-redux";
import audio from '../assets/audio/notify_audio.mp3';
const notificationSound = new Audio(audio);
const cookies = new Cookies();


const Notifications = () => {
    const dispatch = useDispatch();
    const refreshCount = useSelector((state) => state.refreshCount);

    useEffect(() => {
        const interval = setInterval(() => {
            setValues();
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const eventSource = new EventSource(`${web3Config.EMAIL_API}/events`);
    
        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          const user = cookies.get("userObject");
          const userName = user.name;
          const receivedUser = `${data.split("@")[0]}@${web3Config.DOMAIN}`;
          if(receivedUser === userName){
            const notifyMessage =  `New web2 email received`;
            notificationSound.play();
            toast.info(notifyMessage, {
                position: "top-right",
                transition: Bounce,
            });
          }
        };
    
        eventSource.onerror = (error) => {
          console.error('EventSource failed:', error);
          eventSource.close();
        };
    
        return () => {
          eventSource.close();
        };
      }, []);

    const setValues = async () => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            await getCount(web3Instance, accounts[0]);
        }
    }
    let isReload = false;
    let initialCount = 0;
    const getCount = async (web3Value, account) => {
        if (!web3Value || !account) return;
        const user = cookies.get("userObject");
        const userName = user?.name ?? '';
        const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
        const emailList = await contractInstance.methods.getEmailList(userName).call({ from: account });
        const emailDifference = emailList.length - initialCount;
        if (isReload && initialCount < emailList.length) {
            const notifyMessage = emailDifference > 1 ? `New ${emailDifference} emails received` : `New email received`;
            notificationSound.play();
            toast.info(notifyMessage, {
                position: "top-right",
                transition: Bounce,
            });
            dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
        }
        if(emailDifference){
            initialCount = initialCount + emailDifference;
        }
        isReload = true;
    };

    return (
        <>
            {/* The Notifications component currently does not render any visible UI */}
        </>
    );
};

export default Notifications;