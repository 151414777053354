import React, { useState, useEffect } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { formatMessageList } from '../helper/dataFormatHelper';
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from 'react-redux';
const cookies = new Cookies();

const Trash = () => {
    const [user] = useState(cookies.get("userObject"));
    const [web3Value, setWeb3] = useState(null);
    const [account, setAccount] = useState('');
    const [web3Contract, setWeb3Contract] = useState('');
    const [allBoxList, setAllBoxList] = useState([]);
    const [readList, setReadList] = useState([]);
    const [unreadList, setUnreadList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [rendered, setRendered] = useState(false);
    const dispatch = useDispatch();
  
  
    useEffect(() => {
      // Check if MetaMask is installed
      if (window.ethereum) {
        web3AccountCheck(setWeb3 , setAccount);
      } else {
        console.log('MetaMask is not installed');
      }
  
      if(web3Contract){
        setEmail();
      }
  
    }, []);
  
    useEffect(() => {
      if(account && web3Value){
          const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
          setWeb3Contract(contractInstance);
      }
    }, [web3Value, account]);
  
    const getRefreshMessage = useSelector((state) => state.refreshMessage)
    useEffect(() => {
      if(web3Contract){   setEmail(); }
    }, [web3Contract, getRefreshMessage]);
  
    const refreshCount = useSelector((state) => state.refreshCount);
    const reRender = () => { 
      dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
      setEmail(); 
    }
  
    
    async function setEmail(loaderRequired = true) {
  
      try {
  
        if (!web3Contract) return;
  
        if (loaderRequired) {
          setLoader(true);
          setRendered(false);
        }
  
        const emailList = await web3Contract.methods.getEmailList(user.name).call({ from: account });
        const sentEmailList = await web3Contract.methods.getSentEmailList(user.name).call({ from: account });
        const sentlist = await formatMessageList(sentEmailList.reverse(), "Senttrash");
        const formattedResult = emailList
        .filter(email => email.senderName)
        .map(email => ({
          id: parseInt(email.id),
          subject: email.subject,
          encryptedData: email.encryptedData,
          created_at: email.receivedDate,
          isStarred: email.isStarred,
          sender: email.senderName,
          isRead: email.isRead , 
          header :  email.emailDetails , 
          isCC :  email.isCC , 
          isBCC :  email.isBCC ,
          ... email
        }));  
  
        const list = await formatMessageList(formattedResult.reverse(), "Trash");
        const allList = list.deletedList.concat(sentlist.deletedList.filter(message => !message.isPermanentDeleted)).sort((a, b) => new Date(b.date) - new Date(a.date));
        setAllBoxList(allList);
        const readList = allList.filter(message => message.isRead);
        const unReadList = allList.filter(message => !message.isRead);
        setReadList(readList);
        setUnreadList(unReadList);
  
        if (loaderRequired) {
          setRendered(true);
        }
  
      } catch (error) {
        console.log("error", error);
      }
    }
  
    useEffect(() => {
      if(rendered){
        setLoader(false)
      }
    }, [rendered]);
  
  
    return (
      <div className="inbox-container">
          <CommonMessage messageList={allBoxList} pageType={"Trash"} unreadList={unreadList} readList={readList} loader={loader} reRender={reRender}/>
      </div>
    );
  }

export default Trash
