import React , {useState , useEffect, useRef} from 'react'
import { logo } from '../../assets/svg/svg';
import { CompleteLoader, ReplyModal, FileReaderModel } from '../../modals/common-alert';
import Cookies from "universal-cookie";
import config from '../../web3/web3Config.json';
import hostContract from '../../web3/hostContract.json';

import { markMessageAsImportant, web3AccountCheck } from '../../helper/web3helper';
import { convertToLocalDate, optionalValidation, convertToLocalDateforWeb2, getFirstLetterUppercase } from '../../helper/object-validation-helper';
import { downloadFile, getFile, getFileIcon } from '../../helper/attachmentHelper';
import  { Tooltip, Popconfirm }  from 'antd'
import { useSelector } from 'react-redux';
import { deleteEmails } from '../../helper/emailHelper.js';
import { updateInsecureMail } from '../../service/api-actions.js'; 
import Swal from 'sweetalert2'

const contractAddress = config.CONTRACT;

const cookies = new Cookies();
const Decrypt = (props) => {
    const {selectedMessage , decryptedContent} = props;
    const [replyModal, setReplyModal] = useState(false);
    const [messageType, setMessageType] = useState(null);
    const [web3Value, setWeb3] = useState(null);
    const [account, setAccount] = useState('');
    const [loader, setLoader] = useState(false);
    const [decryptFile, setDecryptFile] = useState(null);
    const [isOpenFileReader, setIsOpenFileReader] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [icons, setIcons] = useState({});
    const getFolderIndex = useSelector((state) => state.folderIndex);
    const [messageList , setMessageList ] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const messageContainerRef = useRef(null);

    const [user] = useState(cookies.get("userObject"));
    const messageValue= JSON.parse(decryptedContent);
    const attachmentValue = messageValue.attachment || null;


    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        // Check if MetaMask is installed
        if (window.ethereum) {
          web3AccountCheck(setWeb3 , setAccount);
        } else {
          console.log('MetaMask is not installed');
        }
      }, []);

      useEffect(()=> {
        setMessageList(props.messageList);
    }, [props.messageList])

      useEffect(() => {
        if(!isOpenFileReader){
            const model = document.querySelector('.ant-modal-wrap');
            if(model){
                model.style.display = 'none';
            }
        }
      }, [isOpenFileReader]);

      useEffect(() => {
        const value= JSON.parse(decryptedContent);
        const fetchIcons = async () => {
            const newIcons = {};
            if(value.attachment && value.attachment.length){
                for (const attachment of value.attachment) {
                    const type = Object.keys(attachment)[0];
                    const icon = await getFileIcon(type);
                    newIcons[type] = icon;
                }
            }
            setIcons(newIcons);
        };

        fetchIcons();
        const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
        if (prefersDarkScheme) {
            document.querySelectorAll('.primary-text:not([class^="x_"])').forEach(el => {
                console.log(el);
                el.style.setProperty('color', '#3c4043', 'important');
            });
            document.querySelectorAll('.secondary-text:not([class^="x_"])').forEach(el => {
                console.log(el);
                el.style.setProperty('color', '#70757a', 'important');
            });
        }
      }, [props.decryptedContent]);

    const checkHeight = ()=>{
        if (messageContainerRef.current) {
            const container = messageContainerRef.current;
           if(container.scrollHeight > 200){
            return true;
           }else{
            return false;
           }
        }else{
            return false;
        }
    }

    async function emailActionFunction(type){

        await setMessageType(type);              
        switch (type) {
            case "Reply":
            case "Forward":
                setReplyModal(true);
                break;

            case "Starred":{
                setLoader(true);
                if(selectedMessage && selectedMessage.id && (props.pageType == 'Inbox' || props.pageType == 'Important')){
                    const contractMethods = new web3Value.eth.Contract(hostContract.contract, contractAddress);      
                    const obj = {
                        id : parseInt(selectedMessage.id) ,
                        isStarred : selectedMessage.isStarred
                    }
                    await markMessageAsImportant( contractMethods , user.name , obj , account);  
                    props.reRender();
                }else if(selectedMessage && selectedMessage.id && props.pageType == 'Insecure'){
                    await updateInsecureMail('fav', selectedMessage.id);
                    props.reRender();
                }
                setLoader(false);
                break;
            }
        
            default:
                break;
        }

    }

    const isStarred = optionalValidation(selectedMessage , "isStarred");
    const starSvg = isStarred ? logo.star_svg_fill : logo.star_svg_outline;
    const toOrFrom = props.pageType == "Sent" ? 'To :' : 'From :';
    const toOrFromName = props.pageType == "Sent" ? JSON.parse(selectedMessage.receiver).to[0] : selectedMessage.sender;

  return (
    <div className='encrypted-message-parent-ele'>
    <div className='encrypted-message-header-ele' >

        <div className='topic-ele'>
            <p> Topic :</p>
            <h2> {selectedMessage.subject} </h2>
        </div>

        <div className='actions cursor-pointer'>
            <Tooltip title="Starred"><div onClick={()=> emailActionFunction("Starred")  }  > {starSvg} </div></Tooltip>
            <Tooltip title="Reply all"><div onClick={()=> emailActionFunction("Reply")  }  > {logo.reply_all_svg} </div></Tooltip>
            <Tooltip title="Reply"><div onClick={()=> emailActionFunction("Reply") }> {logo.reply_svg} </div></Tooltip>
            <Tooltip title="Forward"><div onClick={()=> emailActionFunction("Forward")  } > {logo.forward_svg} </div></Tooltip>
            <Tooltip title="Delete">
                <div className='cursor-pointer' onClick={ async()=>{
                    Swal.fire({
                        text: "Are you sure you want to delete?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#1677ff",
                        cancelButtonColor: "#ffffff",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        customClass: "compose-sweet-alert"
                    }).then(async(result) => {
                        if (result.isConfirmed) {
                            setLoader(true); 
                            const folderIndex = getFolderIndex;
                            await deleteEmails(selectedMessage, selectedMessage.id, props.selectedIndex, messageList, props.pageType, folderIndex);
                            props.reRender();
                            props.setSelectedMessageIndex(null);
                            setLoader(false);
                        }
                    }); 
                    }}> 
                    {logo.msg_delete_icon} 
                </div>
            </Tooltip>
            </div>
        </div>

    <div className='encrypted-message-header-ele' >
        <div className='topic-ele'>
            <p>{toOrFrom}</p>
            <div className='user-details-section child-ele-encrypt'>
            <div className='user-profile-pic'>{getFirstLetterUppercase(toOrFromName.split("@")[0])}</div>
                <div className='user-profile-name'>
                    <div className='user-profile-username'>{toOrFromName.split("@")[0]}</div>
                    <div className='user-profile-useremail'>{toOrFromName}</div>
                </div>
            </div>
        </div>

        <div className='actions date-encrypt'>
            <h4> {props.pageType === 'Insecure' ?  convertToLocalDateforWeb2(selectedMessage.date) :convertToLocalDate(selectedMessage.date)}</h4>
            <div> {logo.key_unlock_svg} </div>
        </div>

    </div>

    <div className='encrypted-msg-content decrypt'> 
            <div className='class-msg-component-render decrypt-msg'> 
        { messageValue.event && 
            <div className="row border">
                <div className="col-sm-2 d-flex justify-content-center align-items-center event-date" style={{ height: '10rem' }}>
                    <svg width="75" height="75" viewBox="0 0 20 20">
                        <defs>
                            <linearGradient id="gradientText" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" style={{stopColor:'#18F06E'}} />
                                <stop offset="100%" style={{stopColor:'#18F06E'}} />
                            </linearGradient>
                        </defs>
                        <path d="M18 3.496h-3.001v-.46a1 1 0 10-2 0v.46h-6v-.46a1 1 0 10-1.999 0v.46H2c-.633 0-1 .359-1 1v11.5c0 .641.367 1 1 1h16c.633 0 1-.359 1-1v-11.5c0-.641-.367-1-1-1z" fill="#ffffff"/>
                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="url(#gradientText)" fontWeight='bold' fontSize="4" dy=".3em">
                            {convertToLocalDate(messageValue.event?.[0]?.start).split(/[\s,]/)[1]}
                        </text>
                    </svg>
                    <div className='text-light'>{convertToLocalDate(messageValue.event?.[0]?.start).split(/[\s,]/)[0]}</div>
                </div>
                <div className="col-sm">
                    <p className='my-auto font-size-15 fw-bold'>{ messageValue.event?.[0]?.summary.val ?? messageValue.event?.[0]?.summary}</p>
                    <div className='row d-flex justify-content-center align-items-center mt-2'>
                        <div className='col-sm-1 w-auto'>
                            {logo.time_svg}
                        </div>
                        <div className='col-sm'>
                            <p className='my-auto font-size-11' style={{color: '#979ea8'}}>When</p>
                            <p className='my-auto fw-bold font-size-13'>{convertToLocalDate(messageValue.event?.[0]?.start)} - {convertToLocalDate(messageValue.event?.[0]?.end)}</p>
                        </div>
                    </div>
                    {messageValue.event?.[0]?.attendee.length && 
                        <div className='row d-flex justify-content-center align-items-center mt-2'>
                            <div className='col-sm-1 w-auto'>
                                {logo.people_svg}
                            </div>
                            <div className='col-sm'>
                                <p className='my-auto font-size-11' style={{color: '#979ea8'}}>Who</p>
                                <p className='my-auto fw-bold font-size-13'>{messageValue.event?.[0]?.attendee.length} guests invited</p>
                            </div>
                        </div>
                    }
                    <div className='col-sm border-top d-flex gap-1 mt-1'>
                        <span className='my-auto font-size-11' style={{color: '#979ea8'}}>Are you going?</span>
                    <div class="btn-group btn-group-toggle border mt-1 ml-2" data-toggle="buttons">
                        <button class="btn btn-light">
                           Yes
                        </button>
                        <button class="btn btn-light" style={{ borderLeft: '#dee2e6 solid 1px' }}>
                            Maybe
                        </button>
                        <button class="btn btn-light" style={{ borderLeft: '#dee2e6 solid 1px' }}>
                            No
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        }
            <div>
                <div className={isExpanded ? 'email-contents' : 'email-contents truncated-text'} ref={messageContainerRef} dangerouslySetInnerHTML={{ __html: messageValue.message ?messageValue.message : '' }} />
                {checkHeight() && 
                    <button className='message_show_more_button cursor-pointer' onClick={toggleText}>
                        {logo.show_more_svg}
                    </button>
                }
            </div>
                {attachmentValue && 
                    <div className='attachment-msg-section'> 
                            {attachmentValue.map((attachment, index) => (
                                    <div className='attachment-div-element d-flex-data' key={index} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} onClick={async () => {
                                        try{
                                            setLoader(true);
                                            const decryptedFile = await getFile(Object.keys(attachment)[0], attachment[Object.keys(attachment)[0]]);
                                            setDecryptFile(decryptedFile);
                                            setIsOpenFileReader(true);
                                            setLoader(false);
                                        } catch(e){
                                            console.error('Error getting file:', e);
                                            setLoader(false);
                                        }
                                    }}>
                                        <span className='file-name-preview'>
                                            {Object.keys(attachment)[0]}
                                        </span>
                                        {hoveredIndex !== index && (
                                            <>
                                                <div className='attachment-icon'> {icons[Object.keys(attachment)[0]] || ''}</div>
                                            </>
                                        )}
                                        {hoveredIndex === index && (
                                            <>
                                                <div className='attachment-actions'>
                                                <Tooltip title="Download">
                                                    <div 
                                                        onClick={async (e) => {
                                                            e.stopPropagation();
                                                            setLoader(true);
                                                            await downloadFile(Object.keys(attachment)[0], attachment[Object.keys(attachment)[0]]);
                                                            setLoader(false);
                                                        }}
                                                    >
                                                        {logo.download_svg_attachment}
                                                    </div>
                                                    </Tooltip>
                                                </div>
                                            </>
                                        )}
                                </div>
                            ))}
                    </div>
                }
            </div>
            <CompleteLoader isOpen={loader} />
            <ReplyModal  isModalOpen ={replyModal} close={() => setReplyModal(false)} attachmentValue={attachmentValue} msg={selectedMessage} decryptedMessage={messageValue.message} messageType={messageType} pageType={props.pageType}/>

            {isOpenFileReader &&             
                <FileReaderModel isOpen ={isOpenFileReader} file ={decryptFile} close={() => {
                    setDecryptFile(null)
                    setIsOpenFileReader(false)  
                }  }/>
            }
    </div>  
</div>
  )
}

export default Decrypt
