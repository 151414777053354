import React, { useState, useEffect , useRef } from "react";
import { Modal, Alert, DatePicker, Button } from 'antd';
import "./modal.css"
import 'react-quill/dist/quill.snow.css';
import Cookies from "universal-cookie";
import { sendEmails } from "../helper/emailHelper.js";
import { convertToLocalDate, getCurrentDate, optionalValidation, getFirstLetterUppercase } from "../helper/object-validation-helper.js";
import Loader from "../components/Loader.js";
import { logo } from "../assets/svg/svg.js";
import { transactionAction } from "../helper/chain-helper.js";
import { getHostContract, getHostHelperContract } from "../helper/contract-helper.js";
import { folders } from "../assets/svg/folder-svg.js";
import { getFolderIconsByColor } from "../assets/svg/follder-svg-helper.js";
import { getFile } from '../helper/attachmentHelper.js';
import { sendAttachment } from '../service/api-actions.js';
import { saveSenderEncryptedEmail } from "../helper/emailHelper.js";
import { useSelector } from 'react-redux';
import FileViewer from "react-file-viewer";
import Editor from './Editor';
import { toast, Bounce } from 'react-toastify';
import { getGeneralSettings } from '../helper/settingsHelper.js';
const cookies = new Cookies();

const ReplyModal = (params) => {

  const [sender, setSender] = useState('');
  const [receiver, setReceiver] = useState('');
  const [initialReceiver, setInitialReceiver] = useState('');
  const [subject, setSubject] = useState('');
  const [senderLoader, setSenderLoader] = useState('');
  const [check, setCheck] = useState(true);
  const [user] = useState(cookies.get("userObject"));
  const isReply = params.messageType === "Reply" || "Forward";
  const [loader, setLoader] = useState(false);
  const headerJson = JSON.parse(optionalValidation(params, "msg.header"));
  const created_at = optionalValidation(params, "msg.created_at");
  const decryptedMessage = optionalValidation(params, "decryptedMessage");
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [initialFile, setInitialFile] = useState([]);
  const [accountSettings, setAccountSettings] = useState([]);
  const [CC, setCC] = useState('');
  const [BCC, setBCC] = useState('');
  const [isCC, setIsCC] = useState(false);
  const [isBCC, setIsBCC] = useState(false);
  const [focused, setFocused] = useState(true);

  const formattedDate = convertToLocalDate(created_at);
  const isMessageInclude = useSelector((state) => state.isMessageInclude);
  useEffect(() => {
    setSettingsJson();
  }, []);

  async function setSettingsJson() {
    const contractMethod = getHostContract();

    try {
      const generalSettings = await getGeneralSettings();
      setAccountSettings(generalSettings);      
    } catch (error) {}
    }


  useEffect(() => {
    setSender(user && user.name);
    if(params.pageType === 'Sent'){
      const senderNameValue = params.msg?.receiver ? JSON.parse(params.msg.receiver).to.concat(JSON.parse(params.msg.receiver).cc,JSON.parse(params.msg.receiver).bcc).filter(value => value)[0] : '';
      setInitialReceiver(senderNameValue);
      setReceiver(senderNameValue);
    }else{
      const senderNameValue = isReply ? optionalValidation(params, "msg.sender") : "";
      setInitialReceiver(senderNameValue);
      setReceiver(senderNameValue);
    }  
    

    if (headerJson && headerJson.subject.startsWith("Re: ") || headerJson && headerJson.subject.startsWith("FW: ")) {
      headerJson.subject = headerJson.subject.substring(4);
    }

    if (headerJson && headerJson.subject) {
      if (params.messageType === "Reply") {
        setSubject(`Re: ${headerJson.subject}`);
      } else {
        setReceiver('');
        setSubject(`FW: ${headerJson.subject}`);
      }
    }
    if (params.attachmentValue && params.isModalOpen && isMessageInclude) {
       getfile();
    } else if(params.attachmentValue && params.isModalOpen && !isMessageInclude){
      setInitialFiles(initialFile);
    }
    setCheck(isMessageInclude);
  }, [params]);

  useEffect(() => {
    if(!params.isModalOpen){
      setAttachmentFiles([]);
      setInitialFile([]);
      setFocused(true);
      setIsCC(false);
      setIsBCC(false);
      setBCC('');
      setCC('');
    }
  },[params.isModalOpen]);

  const setInitialFiles = async () => {
    if (params.attachmentValue && params.attachmentValue.length) {
        setLoader(true);
        const filesPromises = params.attachmentValue.map(async (file) => {
          const key = Object.keys(file)[0];
          const value = await getFile(key, file[key]);
          return value;
        });
        const files = await Promise.all(filesPromises);
        setInitialFile(files)
        setLoader(false);
    }
  }
  
  const getfile = async () => {
    try{
      if (params.attachmentValue && params.attachmentValue.length) {
        setLoader(true);
        const filesPromises = params.attachmentValue.map(async (file) => {
          const key = Object.keys(file)[0];
          console.log('Key:', key, 'Value:', file[key]);
          const value = await getFile(key, file[key]);
          return value;
        });
        const files = await Promise.all(filesPromises);
        const combinedFiles = [...attachmentFiles, ...files];
        setInitialFile(files)
        setAttachmentFiles(combinedFiles);
        setLoader(false);
      } else if(initialFile.length){
        setAttachmentFiles(initialFile);
        setLoader(false);
      }
    } catch(e){
      console.error('error fetch file', e);
      params.close();
      setLoader(false);
    }
  }; 
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const combinedFiles = [...attachmentFiles, ...newFiles];
    const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
    const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > maxSize) {
      toast.error("Total file size exceeds the limit of 25 MB.", {
        position: "top-center",
        transition: Bounce,
      });
      return;
    }
    setAttachmentFiles(combinedFiles);
  };
  const handleRemoveFile = (indexToRemove) => {
    setAttachmentFiles(prevFiles => prevFiles.filter((file, index) => index !== indexToRemove));
  }

  const setAttachment = async(value) => {
    setCheck(value);
    if(value == true){
      await getfile();
    } else {
      setAttachmentFiles([]);
    }
  }
  return (
    <div>
      <Modal className="modal-send-email-header parent-div-content-reply-mail" open={params.isModalOpen} onOk={params.close} onCancel={params.close} footer={null}>
        <div className="parent-div-content-reply-mail-child" id='reply-modal'>
          {isReply &&
            <div className='label-title-check-box'> Include Previous Messages
              <input
                type="checkbox"
                id="check-box-reply-email"
                checked={check}
                onChange={() => {
                  setAttachment(!check);
                }}
              />
            </div>
          }
          <div className='send-alert-body-content connect-wallet-account-div reply-email-user reply-email'>
            <div className="email-username reply-user">
              <span>Sender:</span>
              <input className="reply-user-input reply" value={sender} />
            </div>
            <div className="email-username reply-user">
              <span>Recipient:</span>
              <input className="reply-user-input reply" onChange={(e) => {
                 setReceiver(e.target.value);
              }} value={receiver} />
              <span className="compose-cc-bcc" onClick={() => setIsCC(!isCC)}> CC </span>
              <span className="compose-cc-bcc" onClick={() => setIsBCC(!isBCC)} > BCC </span>
            </div>
            {isCC &&
              <div className="email-username reply-user">
                <span>CC:</span>
                <input className="reply-user-input reply" value={CC} onChange={(e)=> {
                    setFocused(false); setCC(e.target.value)
                } } />
              </div>
            }

            {isBCC &&
              <div className="email-username reply-user">
                <span>BCC:</span>
                <input className="reply-user-input reply" value={BCC} onChange={(e)=> {
                  setFocused(false);
                  setBCC(e.target.value)
                }  } />
              </div>
            }
            <div className="email-username reply-user">
              <span>Topic:</span>
              <input className="reply-user-input reply" value={subject} />
            </div>
          </div>
          <Editor
            placeholder="Write something..."
            check={check}
            initialMessage={localStorage.getItem("replyEmail")}
            handleRemoveFile={handleRemoveFile}
            formattedDate={formattedDate}
            initialReceiver={initialReceiver || ''}
            decryptedMessage={decryptedMessage}
          />
          {attachmentFiles.length > 0 && (
            <div className="replay-attachment-file-rendering-method">
              <div className="replay-attachment-section-element">
                {attachmentFiles.map((file, index) => (
                  <a key={index}>{file.name}<span className="delete-icon cursor-pointer" onClick={() => handleRemoveFile(index)}> &#10006;</span></a>
                ))}
              </div>
            </div>
          )}
          <div className="replay-send-section" style={{ marginTop: attachmentFiles.length ? '2.5rem' : '' }}>
          <div className="add-attachment-functionality">  
              <div className="btn-attachment cursor-pointer"  onClick={() => document.getElementById('fileInput').click()}> 
                  {logo.attachment_icon} <span className="add-file-component"> add file </span>  
              </div>
              <input 
                type="file" 
                id="fileInput"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />                               
          </div>
          <div className="send-reply-email" onClick={async () => {
              const isSavedOn = accountSettings.isSaveSent;
              setLoader(true);
              const recipient = receiver;
              const cc = isCC ? CC : "" ;
              const bcc = isBCC ? BCC : "";
              let defaultEncryptedMessage = "MSG";
              if (isSavedOn) {
                const emailObject = { recipient: recipient, subject: subject, message: localStorage.getItem("replyEmail") };
                if(attachmentFiles && attachmentFiles.length){  
                  const attachmentResult = await sendAttachment(attachmentFiles , user.publicKey);
                  emailObject.attachment = attachmentResult.data.returnValue;
                }
                const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                defaultEncryptedMessage = await saveSenderEncryptedEmail(emailObject, accounts);
              }
              const result = await sendEmails(recipient.replace(/\s/g, '').split(","), cc.replace(/\s/g, '').split(","), bcc.replace(/\s/g, '').split(","), subject, localStorage.getItem("replyEmail"), isSavedOn, defaultEncryptedMessage, attachmentFiles);
              setLoader(false);
              if(result){
                params.close(false);
              }
            }}> {senderLoader ? <div className="loader-ring-reply"></div> : `Send`} </div>
          </div>
        </div>
      </Modal>
      {loader &&
        <CompleteLoader isOpen={loader} />
      }
    </div>
  )
}


const CompleteLoader = (params) => {  
  return (
    <div>
      <Modal className="modal-loader-common" open={params.isOpen} onOk={params.close} onCancel={params.close} closeIcon={null} footer={null}>
        <Loader />
      </Modal>
    </div>
  );

}

const QuickSendModal = (params) => {
  const [user] = useState(cookies.get("userObject"));
  const [loader, setLoader] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [quickSearch, setQuichSearch] = useState('');
  const [userList, setUserList] = useState([]);
  const [filterUserList, setFilterList] = useState([]);
  const [newUser, setNewUser] = useState('');
  const [alertInfo, setAlertInfo] = useState({});
  const placeHolder = userList.length ? "Search User" : "No User Found";

  async function quickAccessAction(userValue , index){

    if(!isQuickList(userValue)){
      setLoadingIndex(index); // Set the index of the user being processed
      setLoader(true);
      const host = getHostHelperContract();
      const functionParams = [user.name, userValue, userValue.split("@")[0]];
      await transactionAction(host, "saveQuickSend", functionParams, user.wallet);
      setLoadingIndex(null);
      setLoader(false);
      params.close();
      
    } else {
      setAlertInfo({ message: 'User already added in quick send', type: 'warning', autoClose: 5000, show: true });
    }
  }

  useEffect(() => {
    if(quickSearch.length){
      const filter = userList.filter(value => value.includes(quickSearch));
      setFilterList(filter);
    }else{
      setFilterList(userList)
    }
  },[quickSearch])

  useEffect(() => {
    setQuichSearch('');
    setNewUser('');
    setAlertInfo({ message: '', type: '', autoClose: null, show: false });
    setUserList(params.addressList);
    setFilterList(params.addressList);
  },[params])

  const isQuickList = (userValue) => {
    return params.quickUserList.filter(list => list.userEmail == userValue).length == 0 ? false : true;
  }

  const validateUser = async (userValue) => {
    const emailRegex = /^[a-zA-Z0-9._@]+$/;
    if (!emailRegex.test(userValue)) {
      setAlertInfo({ message: 'Please enter a valid email address.', type: 'warning', autoClose: 5000, show: true });
      return false;
    }
    const atCount = (userValue.match(/@/g) || []).length;
    if (atCount !== 1) {
      setAlertInfo({ message: 'Please enter a valid email address.', type: 'warning', autoClose: 5000, show: true });
      return false;
    }
    return true;
  }

  const handleCloseAlert = () => {
    setAlertInfo({ ...alertInfo, show: false });
  };

  return (
    <div>
      <Modal className="modal-loader-quick-send" open={params.isOpen} onOk={params.close} onCancel={params.close} closeIcon={null} footer={null}>
        {alertInfo.show && <CustomAlert message={alertInfo.message} type={alertInfo.type} autoClose={alertInfo.autoClose} onClose={handleCloseAlert} />}
        <div className="quicksend-container">

          <div className="quick-send-header">
            <span>Add Contact for Quick Send</span>
            <div className="cursor-pointer" onClick={() => {  params.close();  }}> {logo.close_icon_common} </div>
          </div>

          <div className="field-records-containers">
            <div className="input-field-search-user">
              <input className="input-search-user" type="text" onChange={(e) => { setAlertInfo({ show: false }); setQuichSearch(e.target.value) }} value={quickSearch} placeholder={placeHolder} />
              {logo.dmail_search_icon_header}
            </div>

            <div className="user-rendering-dynamic">
                <div className="user-section-add-user">
                  <div className='user-details-section user-profile-render-data'>
                  <div className='user-profile-pic'>{newUser.charAt(0).toUpperCase() || '#'}</div>
                  <div className='user-profile-name inbox quick-send input-field-search-user'>
                    <input className="input-search-user" type="text" onChange={(e) => { setAlertInfo({ show: false }); setNewUser(e?.target?.value?.trim()?.toLowerCase()) }} value={newUser} placeholder="Add New User" maxLength="225" />
                  </div>
                  <div title="Add to Quick Access" className="add-user-icon-quick cursor-pointer" onClick={async () => {  
                    const isValid = await validateUser(newUser);
                    if(isValid) { quickAccessAction(newUser, '#'); }
                  }}>
                      {loadingIndex === '#' ? <Loader normal={true} /> : logo.quick_add_svg}
                  </div>
                </div>
                <hr />
              </div>
              {filterUserList.map((userValue , index) => {

                const data = isQuickList(userValue);
                return (
                  <div className="user-section-add-user" key={index}>
                    <div className='user-details-section user-profile-render-data'>
                      <div className='user-profile-pic'>{getFirstLetterUppercase(userValue)}</div>
                      <div className='user-profile-name inbox quick-send'>
                        <div className='user-profile-username'>{userValue.split("@")[0]}</div>
                        <div className='user-profile-useremail'>{userValue}</div>
                      </div>
                        <div title={!data? "Add to Quick Access" : "Already Added"} className={"add-user-icon-quick cursor-pointer"+( data ?" disable-value":"")} onClick={async () => {  quickAccessAction(userValue  , index); }}> 
                            {loadingIndex === index ? <Loader normal={true} /> : logo.quick_add_svg}
                        </div>
                    </div>
                    <hr />
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      </Modal>

      {/* <CompleteLoader isOpen={loader} /> */}

    </div>
  );

}


const CreateFolderModal = (params) => {
  const [user] = useState(cookies.get("userObject"));
  const [folderColor, setFolderColor] = useState("green");
  const [folderName, setFolderName] = useState("");
  const [loader, setLoader] = useState(false);

  const availableColors = Object.keys(folders.folderIcon).map(folder => folder.replace('Folder', ''));


  async function addFolderOnChain(){

    setLoader(true);

    const folderJson = [{key: "color", value: folderColor}];
    const functionParams  = [user.name , folderName , getCurrentDate() , folderJson ];
    const helperContract = getHostHelperContract();
    await transactionAction(helperContract, "createFolder", functionParams, user.wallet);
    setDefault();

    setLoader(false);
    params.close();
    
  }

  function setDefault(){
    setFolderColor("green");
    setFolderName("")
  }


  return (
    <div>
      <Modal
        className="modal-loader-quick-send"
        open={params.isOpen}
        onOk={params.close}
        onCancel={params.close}
        closeIcon={null}
        footer={null}
      >
        <div className="quicksend-container">
          <div className="quick-send-header">
            <span>Add New Folder</span>
            <div onClick={params.close}>
              {logo.close_icon_common}
            </div>
          </div>

          <div className="folder-div-parent-container">
            <div className="folder-input-element">
              <div className="folder-name-update">Folder Name</div>
              <div className="folder-dynamic-content-type">
                {folders.folderIcon[`${folderColor}Folder`]}
                <div className="input-div-folder">
                  <input
                    className="input-folder"
                    type="text"
                    placeholder="Folder Name"
                    onChange={(e) => setFolderName(e.target.value)}
                    value={folderName}
                  />
                </div>
              </div>
            </div>

            <div className="folder-color-update-div">
              <div className="folder-name-update no-margin">Folder Color</div>
              <div className="folder-colder-update">
                {availableColors.map((color, index) => (
                  <div className="folder-type-color" key={index}>
                    {folders.folderIcon[`${color}Folder`]}

                    <div 
                        className="radio-button-section" 
                        onClick={() => setFolderColor(color)}
                      >
                        {folderColor === color ? (
                          getFolderIconsByColor(
                            folders.colorCombination[color].from, 
                            folders.colorCombination[color].to
                          )
                        ) : (
                          folders['noChecked']
                        )}
                      </div>

                  </div>
                ))}
              </div>
            </div>

            <div className="add-folder-parent-div"> 
                <button disabled={!folderName} className="add-folder-btn cursor-pointer" onClick={()=> {  addFolderOnChain() }}> 
                    Add Folder
                </button>
            </div>
          </div>
        </div>
      </Modal>

      <CompleteLoader isOpen={loader} />
    </div>
  );
};

const FileReaderModel = (params) => {
  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState('');
  const fileViewerRef = useRef(null);
  const getFileType = (fileName) => fileName.split(".").pop();
  useEffect(() => {
    if (params.file) {
      const fileURL = window.URL.createObjectURL(params.file);
      setFileData(fileURL);
      setFileType(getFileType( params.file.name));
      return () => window.URL.revokeObjectURL(fileURL);
    } else {
      setFileData(null);
      setFileType('');
    }
  }, [params.file]);

  useEffect(() => {
    if (!params.isOpen) {
      fileViewerRef.current = null;
    }
  }, [params.isOpen]);

  const renderFileViewer = (fileDatas, fileTypes) => {
    if (fileDatas && fileTypes) {
      console.log(fileDatas);
      try {
        return (
          <FileViewer
            ref={fileViewerRef}
            fileType={fileTypes}
            filePath={fileDatas}
            errorComponent={<p>Error loading file</p>}
            onError={(error) => console.error("FileViewer error: ", error)}
          />
        );
      } catch (error) {
        console.error("Error rendering FileViewer: ", error);
        return <p>Error rendering file viewer</p>;
      }
    } else {
      return <p>No file selected</p>;
    }
  };

  return (
    <Modal
      className="parent-div-content-file-preview"
      open={params.isOpen}
      onOk={params.close}
      onCancel={params.close}
      closeIcon={null}
      footer={null}
    >
      <div className="quicksend-container">
        <div className="file-preview-header">
          <span>File Preview - {params.file.name}</span>
          <div onClick={params.close}>
            {logo.compose_close}
          </div>
        </div>

        <div className="file-rendering-section">
          {renderFileViewer(fileData, fileType)}
        </div>
      </div>
    </Modal>
  );
};

const CustomAlert = ({ message, type, autoClose, onClose }) => {
  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(onClose, autoClose);
      return () => clearTimeout(timer);
    }
  }, [autoClose, onClose]);
  return (
    <Alert message={message} type={type} showIcon onClose={onClose} closable /> 
  );
};

export {
  ReplyModal,
  CompleteLoader,
  QuickSendModal,
  CreateFolderModal,
  FileReaderModel,
  CustomAlert
}
