import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from 'antd';
import ReactQuill from 'react-quill';
import { calender_svg } from "../assets/svg/calender_svg";
import { transactionAction } from '../helper/chain-helper';
import { getHostHelperContract } from '../helper/contract-helper';
import { toast, Bounce } from 'react-toastify';

const Signature = ({ isOpen, close, loader, settings, user, account}) => {
    const [note, setNote] = useState('');
    const [isHtml, setIsHtml] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const quillRef = useRef(null);

    useEffect(() => {
        if(isOpen){
            Object.values(settings).filter(value => value.key === 'signature').map((opt) => {
                opt.options.map((optVal) => {
                    if (optVal.key === 'signatureText') setNote(optVal.value);
                    if (optVal.key === 'isHtml') setIsHtml(optVal.value.toLowerCase() === "true");
                    if (optVal.key === 'isActive') setIsActive(optVal.value.toLowerCase() === "true");
                });
            });
        }
    }, [isOpen]);
    
    const saveSignature = async () => {
        loader(true);
        const options = [
            {
                key: "isHtml",
                value: isHtml.toString()
            },
            {
                key: "signatureText",
                value: note
            },
            {
                key: "isActive",
                value: isActive.toString()
            }
        ];

        const helperContract = getHostHelperContract();  
        const functionParams = [ user.name, 'signature', options];
        const hash = await transactionAction(helperContract , "updateSettings", functionParams , account);
        if(hash) {
            toast.success("Successfully updated.", {
                position: "top-right",
                transition: Bounce,
            });
        }else{
            toast.error("Something went wrong.", {
                position: "top-right",
                transition: Bounce,
            });
        }
        loader(false);
        close();
    }

    const editorConstant = {
        toolBar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ 'size': [] }],
          ['bold', 'italic', 'underline'],
          ['clean']
          [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ],
        format: [
          'header', 'font', 'size',
          'bold', 'italic', 'underline',
          'list', 'bullet', 'indent'
        ]
      }

      return (
        <>
            <Modal
            className="parent-div-content-create-event"
            open={isOpen}
            onOk={close}
            onCancel={close}
            closeIcon={null}
            footer={[<Button key="submit" className="mt-1rem save-signature-btn" onClick={()=>{saveSignature()}}>Save</Button>]}
            >
                <div className="quicksend-container">
                    <div className="event-preview-header">
                    <span className="event-preview-header-text"><span className="text-gradient">Signature</span> 
                        <span className="event-preview-header-down-icon"></span>
                    </span>
                    <div onClick={close} className="cursor-pointer">
                        {calender_svg.close_svg}
                    </div>
                    </div><hr></hr>
                    <div className="file-rendering-section">
                        <div className="flex flex-wrap gap-1rem mt-1rem">
                            <label><b>Use html:</b></label>
                            <label className="toggle-settings">
                                <input
                                    checked={isHtml}
                                    type="checkbox"
                                    onChange={() => setIsHtml(!isHtml)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="flex flex-wrap gap-1rem mt-1rem">
                            <label><b>Active:</b></label>
                            <label className="toggle-settings">
                                <input
                                    checked={isActive}
                                    type="checkbox"
                                    onChange={() => setIsActive(!isActive)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    <label className="mt-1rem"><b>Signature text:</b></label>
                    <ReactQuill className="eventEditor mt-2" ref={quillRef} modules={{
                        toolbar: editorConstant.toolBar,
                        }} value={note} onChange={(e) => setNote(e)}/>
                    </div>
                </div>
            </Modal>
        </>
      );
}


export {
    Signature
}