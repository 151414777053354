import React,{useState , useEffect , useRef} from 'react'
import { logo } from '../assets/svg/svg';
import { getHostContract, getHostHelperContract } from '../helper/contract-helper';
import Cookies from "universal-cookie";
import { markMessageAsImportant } from '../helper/web3helper';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteLoader, ReplyModal } from '../modals/common-alert';
import ContextMenu from './context/ContextMenu';
import { convertToLocalDate, getFirstLetterUppercase, returnStringJson, convertToLocalDateforWeb2 } from '../helper/object-validation-helper';
import { getSettings } from "../helper/settingsHelper.js";
import { deleteEmails } from '../helper/emailHelper.js';
import { updateInsecureMail, getEncryptedMessage } from '../service/api-actions.js'; 
import { syncIndexedDB } from '../db/indexed-helper.js';
import { transactionAction } from '../helper/chain-helper.js';
import contractData from '../web3/hostContract.json';
import config from '../web3/web3Config.json';
import { Tooltip, Popconfirm } from 'antd';
import Web3 from 'web3';
import Swal from 'sweetalert2'
import { toast, Bounce } from 'react-toastify';
import NoMessage from './NoMessage';
import { calender_svg } from "../assets/svg/calender_svg.js";
import { getGeneralSettings } from '../helper/settingsHelper.js';
const cookies = new Cookies();

const Messages = (props) => {

    const [user] = useState(cookies.get("userObject"));
    const [messageList , setMessageList ] = useState([]);
    const [loader , setLoader ] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const [contextMenuVisible, setContextMenuVisible] = useState(false); // State to manage context menu visibility
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 }); // State to manage context menu position
    const [selectedContext, setContext] = useState(false);  
    const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [replyModal, setReplyModal] = useState(false);
    const [modalType, setModalType] = useState(false);   
    const [attachment, setAttachment] = useState([]);
    const [checkedCollection, setCheckedCollection] = useState([]);
    const isClickableStar = ["Important", "Inbox", "Insecure"].includes(props.pageType);
    const isDragable = ["Important", "Inbox", "Sent"].includes(props.pageType);
    const [currentPage, setCurrentPage] = useState(1);
    const [messagesPerPage, setMessagesPerPage] = useState(50);
    const [openChildIndex, setOpenChildIndex] = useState([]);
    const dispatch = useDispatch();    
    const dragImageRef = useRef(null);
    const pagiClickRight = useSelector((state) => state.pagiClickRight);
    const pagiClickLeft = useSelector((state) => state.pagiClickLeft);

    const contractAddress = config.CONTRACT;
    const web3 = new Web3(window.ethereum);
    const contractMethods = new web3.eth.Contract(contractData.contract, contractAddress);
    const selectedType = useSelector((state) => state.selectedType);
    // Compute paginated messages
    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = messageList.slice(indexOfFirstMessage, indexOfLastMessage);
    const totalPages = Math.ceil(messageList.length / messagesPerPage);

    // Pagination Controls
    const handlePageChange = (pageNumber) => {
        if(totalPages < pageNumber || pageNumber < 1) return;
        setCurrentPage(pageNumber);
        dispatch({ type : "CURRENT_PAGE",   payload :  pageNumber } );
    };

    useEffect(()=> {
        // setCurrentPage(1);
        // dispatch({ type : "CURRENT_PAGE",   payload :  1 } );
        setMessageList(props.messageList);        
        fetchData();
    }, [props.messageList])

    useEffect(() => {
        setIncludeMessage();
        const dragImageElement = document.createElement('div');
        dragImageElement.className = 'drag-image';
        dragImageElement.innerText = 'move this conversation';
        document.body.appendChild(dragImageElement);
        dragImageRef.current = dragImageElement;
    
        return () => {
          document.body.removeChild(dragImageElement);
        };
      }, []);

      async function setIncludeMessage() {
        const settingsJson = await getGeneralSettings();
        const insecure =settingsJson.isIncludePrevious;
        dispatch({ type : "IS_INCLUDE_MESSAGE",   payload :  insecure } );
      }

    async function fetchData(){
        const helperContract = getHostHelperContract();
        const folderRecords = await helperContract.methods.getFolders(user.name).call({ from: user.wallet });
        setFolderList(folderRecords);

    }

    const filterType = useSelector((state) => state.filterType); 
    const parentClassName = filterType ? "sort-added" : "sort-inactive";
    
    const handleContextMenu = async (e , item) => {
        e.preventDefault();
        await fetchData();
        if(props.pageType === 'Trash'){
            setContextMenuVisible(true);
            setContextMenuPosition({ x: e.clientX, y: e.clientY });
            setContext(item);
        }
        if(props.pageType === 'Archive'){
            setContextMenuVisible(true);
            setContextMenuPosition({ x: e.clientX, y: e.clientY });
            setContext(item);
        }
        if(!isDragable) return true;
        setContextMenuVisible(true);
        setContextMenuPosition({ x: e.clientX, y: e.clientY });
        setContext(item);
    };


      // Close context menu
  const handleCloseContextMenu = () => {
    setContextMenuVisible(false);
};


const dragStart = (e, message, type) => {
    const dragImage = dragImageRef.current;
    if (dragImage) {
        e.dataTransfer.setDragImage(dragImage, 0, 0);
        e.dataTransfer.setData('message_id', message.id);
        e.dataTransfer.setData('message_type', type);
    }
};


const actionClick = async(message , type) => {

    if(props.pageType === "Draft"){
        return true;
    }
    setLoader(true);
    if(!message.decryptedMessage){
        const data = await getEncryptedMessage(message.encryptedMsg);
        const encryptedMsg = data.returnValue ? data.returnValue : message.encryptedMsg;
        const functionNames = {
            "Sent" : "markSentEmailAsRead",
            "Inbox" : "markEmailAsRead",
          }
        try{
            const accounts = await window.ethereum.request({ method: 'eth_accounts' });
            const decryptedMsg = await window.ethereum.request({ method: 'eth_decrypt', params: [encryptedMsg, accounts[0]] });
            const obj = JSON.parse(decryptedMsg);
            if(message.emailType && message.emailType === 'Senttrash'){
                syncIndexedDB(message.id , decryptedMsg, 'Senttrash');
            }else{
                syncIndexedDB(message.id , decryptedMsg, props.pageType);
            }
            const functionParams = [user.name, message.id];
            if(props.pageType == "Trash" && !message.isRead && message.emailType && message.emailType === 'Senttrash'){
                await transactionAction(contractMethods, 'markSentEmailAsRead' , functionParams, accounts[0]);
            }else{
                if(!message.read && props.pageType !== "Insecure"){
                    const functionName = functionNames[props.pageType] || "markEmailAsRead";
                    await transactionAction(contractMethods, functionName , functionParams, accounts[0]);
                }
            }
             if( props.pageType == "Insecure" && !message.isRead){
               await updateInsecureMail('isRead', message.id);
             }
            if(obj.attachment){
                setAttachment(obj.attachment);
            }
            setMessage(obj.message);
        } catch(e){
            setLoader(false);
            return true;
        }
    } else{
        if(message.decryptedMessage.attachment){
            setAttachment(message.decryptedMessage.attachment);
        }
        setMessage(message.decryptedMessage.message)
    }
    setSelectedMessage(message);
    setLoader(false);
    setReplyModal(true);
    setModalType(type);
}

useEffect(()=> {
    setAttachment([]);
}, [replyModal])

const fetchSenderReceiver = (message) => {
    if (props.pageType === "Sent") {
        try {
            const receiverObj = JSON.parse(message.receiver);
            const to = receiverObj.to.join(', ');
            return `${to}`;
        } catch (error) {
            console.error("Error parsing receiver JSON:", error);
            return ''; 
        }
    } else {
        return message.sender.split("@")[0];
    }
};

const getFolderIndex = useSelector((state) => state.folderIndex);
const deleteMessage = async (message, msgId, index) => {
    Swal.fire({
        text: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1677ff",
        cancelButtonColor: "#ffffff",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: "compose-sweet-alert"
    }).then(async(result) => {
        if (result.isConfirmed) {
            setLoader(true);  
            const folderIndex = getFolderIndex;
            await deleteEmails(message, msgId, index, messageList, props.pageType, folderIndex);   
            props.reRender();
            props.setSelectedMessageIndex(null);
            setLoader(false);
        }
    }); 

}

const dragEnd = (e) => {
    // Reset cursor to default
    document.body.classList.remove('grabbing');
  };

  useEffect(() => {
    switch (selectedType) {
      case 1:
        const list1 = currentMessages.filter((item) => item.id).map((item) => item.id);
        setCheckedCollection(list1);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  list1 } );
        break;
      case 2:
        const list2 = currentMessages.filter((item) => item.isRead).map((item) => item.id);
        setCheckedCollection(list2);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  list2 } );
        break;
      case 3:
        const list3 = currentMessages.filter((item) => !item.isRead).map((item) => item.id);
        setCheckedCollection(list3);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  list3 } );
        break;
      case 4:
        const list4 = currentMessages.filter((item) => item.isStarred).map((item) => item.id)
        setCheckedCollection(list4);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  list4 } );
        break;
      default:
        setCheckedCollection([]);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  [] } );
        break;
    }
  }, [selectedType]);

  const handleChecked = (message) => {
    if(checkedCollection.includes(message.id)) return true;
  }

  const selectMessage = (id) => {
    if (checkedCollection.includes(id)) {
        const list = checkedCollection.filter((item) => item !== id);
        setCheckedCollection(list);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  list } );
      } else {
        setCheckedCollection([...checkedCollection, id]);
        dispatch({ type : "SELECTED_MESSAGE_LIST",   payload :  [...checkedCollection, id] } );
      }
  }
 const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPagesToShow = 5;
    
    if (totalPages <= totalPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }
    return pageNumbers;
  };

    const handleItemsPerPageChange = (event) => {
        setMessagesPerPage(Number(event.target.value));
        dispatch({ type : "MESSAGE_PER_PAGE",   payload :  Number(event.target.value) } );
        setCurrentPage(1);
        dispatch({ type : "CURRENT_PAGE",   payload :  1 } );
    };

    useEffect(()=> {
        handlePageChange(currentPage + 1);
    }, [pagiClickRight])

    useEffect(()=> {
        handlePageChange(currentPage - 1);
    }, [pagiClickLeft])
    useEffect(()=> {
        handlePageChange(1);
    }, [])

    const reRender = ()=>{
        props.reRender();
    }
    
    const deleteLastThirty = ()=>{
        Swal.fire({
            text: "Are you sure you want to trash more than 30 days?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async(result) => {
            if (result.isConfirmed) {
                const parseDate = (dateString) => new Date(dateString);
                const today = new Date();
                const thirtyDaysAgo = new Date(today);
                thirtyDaysAgo.setDate(today.getDate() - 30);
                const recentMessages = currentMessages.filter(message => {
                  const receivedDate = parseDate(message.receivedDate);
                  return receivedDate < thirtyDaysAgo;
                });
                if(!recentMessages.length){
                    toast.info('No data found!', {
                        position: "top-right",
                        transition: Bounce,
                    });
                    return;
                }
                setLoader(true);
                await deleteEmails(recentMessages, null, null, null, 'emptyTrash', null);
                setLoader(false);
                props.reRender();
            }
        });
    }

    const emptyTrash = ()=>{
        Swal.fire({
            text: "Are you sure you want to empty trash?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1677ff",
            cancelButtonColor: "#ffffff",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: "compose-sweet-alert"
        }).then(async(result) => {
            if (result.isConfirmed) {
                setLoader(true);
                await deleteEmails(currentMessages, null, null, null, 'emptyTrash', null);
                setLoader(false);
                props.reRender();
            }
        });
    }

    const messageContents = (message, index)=>{

        return(
            <>
                <div className={'row-message-box-child' + ` ${message.read ? "Readed" : "Unreaded"} ${parentClassName}`} draggable={isDragable ? 'true' : 'false'} onDragStart={(e) => dragStart(e, message, props.pageType)}  onDragEnd={dragEnd}  onContextMenu={(e)=> handleContextMenu(e , message )} onMouseEnter={() => setHoveredMessageIndex(message.id)} onMouseLeave={() => setHoveredMessageIndex(null)}  key={index} 
                    onClick={() => {

                        if(props.pageType === "Draft") {
                            const draftJSON = message;
                            localStorage.setItem("recipient", draftJSON.to);
                            localStorage.setItem("topic", draftJSON.subject);
                            localStorage.setItem("cc", draftJSON.cc)
                            localStorage.setItem("bcc", draftJSON.bcc);
                            localStorage.setItem("sendingEmail", draftJSON.message);
                            dispatch({ type : "DRAFT",   payload :  JSON.stringify(message)} );
                            return true;
                        } else if(props.pageType === "Outbox") {
                            dispatch({ type : "OUTBOX", payload :  index , value: true});
                            return true;
                        } 

                        props.setSelectedMessageIndex(index);
                        props.openMessage(message);

                        if(message.decryptedMessage){
                            props.setIsDecrypted(true);
                            props.setDecryptedContent(JSON.stringify(message.decryptedMessage));
                        }
                    }}
                >

                    <div className='user-check-section' id='list-message-component'>
                        <div className='check-box-ele-user'>
                            <input type="checkbox" id="html" checked={handleChecked(message)} onClick={ async (e)=>{ e.stopPropagation(); selectMessage(message.id); } }/>
                        </div>
                        <div className='check-box-ele-user' style={{ cursor: isClickableStar ? 'pointer' : 'no-drop' }} onClick={ async (e)=>{
                            e.stopPropagation();
                            e.preventDefault();
                            if(props.pageType === "Insecure"){
                                setLoader(true);
                                await updateInsecureMail('fav', message.id);
                                setLoader(false);
                                props.reRender();
                                return;
                            } else if(props.pageType === "Important" || props.pageType === "Inbox"){
                                setLoader(true);
                                const contractMethods = getHostContract();   
                                const obj = {
                                    id : parseInt(message.id) ,
                                    isStarred : message.isStarred
                                }
                                await markMessageAsImportant( contractMethods , user.name , obj , user.wallet );  
                                setLoader(false);
                                props.reRender();
                            }
                            setLoader(false);
                        }}>
                            {message.isStarred &&   
                                     <div className='start-icon-div'> {logo.star_svg_fill}  </div>          
                            }

                            {!message.isStarred &&   
                                     <div className='start-icon-div' > {logo.star_svg_outline}  </div>          
                            }
                        </div>
                        {/* <div className="ncog-seperation"> | </div> */}
                    </div>

                    <div className='user-details-section message-section'>
                    <div className='user-profile-pic'>{getFirstLetterUppercase(fetchSenderReceiver(message))} </div>
                        <div className='user-profile-name inbox mail-username'>
                            <div className='user-profile-username'>{props.pageType === "Sent" ? "To: " : ""}{fetchSenderReceiver(message)}</div>
                            { props.pageType !== "Sent" &&
                                <div className='user-profile-useremail'>{message.sender}</div>
                            }
                        </div>
                        {/* <div className="ncog-seperation inbox-value"> | </div> */}
                    </div>

                    <div className='user-subject-section'>
                        Topic : <span> {message.subject} </span>
                    </div>

                    

                    <div className='user-encryption-section' onClick={(e)=> {
                        e.stopPropagation();
                    }}>
                    <div className='user-date-section' style={hoveredMessageIndex === message.id ? { display: 'none' } : {}}>
                        {props.pageType === 'Insecure' ? convertToLocalDateforWeb2(message.date) : convertToLocalDate(message.date)}
                    </div>
                    {hoveredMessageIndex === message.id ? (
                            <div className='hover-reply'>
                                {props.pageType != "Draft" && props.pageType != "Outbox" &&
                                <>                                   
                                    <Tooltip title="Reply all"><div onClick={()=> { actionClick(message , "Reply"); }} > {logo.back_arrow_reply} </div></Tooltip>
                                    <Tooltip title="Reply"><div onClick={()=> { actionClick(message , "Reply"); }} >  {logo.default_back_reply} </div></Tooltip>
                                    <Tooltip title="Forward"><div onClick={()=> { actionClick(message , "Forward"); }} > {logo.forward_reply_svg} </div></Tooltip>
                                </>
                                }
                                <Tooltip title="Delete">
                                    <div onClick={()=>{
                                        deleteMessage(message, message.id, index);
                                    }}> {logo.delete_reply_svg} </div>
                                </Tooltip>
                            </div>
                        ) : (
                            <>
                            <div className='lock-logo'>
                            {message.decryptedMessage && logo.key_unlock_svg}
                            {!message.decryptedMessage && logo.key_lock_svg}
                            </div>
                            </>
                        )}
                    </div>

                   </div>
            </>
        )

    }
    const setChildIndex = (index) => {
        openChildIndex.includes(index) ? setOpenChildIndex(openChildIndex.filter(item => item !== index)) : setOpenChildIndex([...openChildIndex, index])
    }

    const checkReaded =(message) => {
        return message.data.every((item) => item.read);
    }
    return (
        <>
        {props.pageType === 'Trash' && 
            <div className="card trash-header">
                <p className="card-header d-flex align-center justify-content-center title">Delete Messages that have been in <span className='gradient-text cursor-pointer' onClick={()=>{ deleteLastThirty() }}>&nbsp;Trash more than 30 days&nbsp;</span>  or <span className='gradient-text cursor-pointer'  onClick={()=>{ emptyTrash() }}>&nbsp;Empty Trash now&nbsp;</span></p>
            </div>
        }
            {currentMessages.map((message, index) => (
                <React.Fragment>
                    {!message.groupKey ? (
                    <div>{messageContents(message, index)}</div>
                    ) : (
                    <div>
                        <div className={`row-message-box-child user-select-none +  ${checkReaded(message) ? "Readed" : "Unreaded"}`} onClick={()=>{setChildIndex(index)}}>
                            <div className='user-check-section'>
                                <span style={{rotate:!openChildIndex.includes(index) ? '270deg' : '', marginLeft: '-20px'}}>{calender_svg.down_svg}</span>
                                <div className='check-box-ele-user gap-10'>
                                    <input type="checkbox" id='html' onClick={ async (e)=>{ e.stopPropagation() }} />
                                    <div className='start-icon-div'> {logo.star_svg_outline}  </div>
                                </div>
                            </div>
                            <div className='user-details-section message-section'>
                                <div class="user-profile-pic">{getFirstLetterUppercase(message.groupKey.split('___')[0] ?? '#')}</div>
                                <div className='user-profile-name inbox mail-username'>
                                    <div className='user-profile-username'>{props.pageType == "Sent" ? `To: ${message.groupKey.split('___')[0] ?? ''}` : message.groupKey.split('___')[0].split('@')[0] ?? ''}{}</div>
                                    {props.pageType !== "Sent" &&
                                        <div className='user-profile-useremail'>{message.groupKey.split('___')[0] ?? ''}</div>
                                    }
                                </div>
                            </div>
                            <div className='user-subject-section'>
                            Topic : <span>{message.groupKey.split('___')[1] ?? ''}</span>
                            </div>
                            <div className='user-encryption-section'>
                                <div className='user-date-section'>
                                    {props.pageType === 'Insecure' ? convertToLocalDateforWeb2(message.date) : convertToLocalDate(message.date)}
                                </div>
                            </div>
                        </div>
                        {openChildIndex.includes(index) &&
                            <div className='group-emails'>
                                {message.data.map((msg, ind) => (
                                    <div>{messageContents(msg, ind, true)}</div>
                                ))}
                            </div>
                        }
                    </div>
                    )}
                </React.Fragment>
            ))}

        {/* Hidden drag image */}
        <div ref={dragImageRef} style={{ display: 'none' }}>
            <div className="drag-image">move this conversation</div>
        </div>
            <CompleteLoader isOpen={loader} />
            {contextMenuVisible && (
                <ContextMenu 
                    x={contextMenuPosition.x} 
                    y={contextMenuPosition.y} 
                    onClose={handleCloseContextMenu} // Pass function to close context menu
                    types={folderList}
                    selectedContext ={selectedContext}
                    loader={setLoader}
                    pageType={props.pageType}
                    reRender={reRender}
                />
            )}
            {currentMessages.length > 0 &&
                <div className="pagination-controls">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>{logo.left_arrow_svg}</button>
                    {getPageNumbers().map((page, index) => (
                        
                        <button
                            key={index}
                            onClick={() => handlePageChange(page)}
                            className={page === currentPage ? 'active' : ''}
                            disabled={page === '...'}
                        >
                            {page}
                        </button>
                    ))}
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>{logo.right_arrow_svg}</button>
                    <span>per page:</span>
                    <select className="items-per-page-select"
                        value={messagesPerPage}
                        onChange={handleItemsPerPageChange}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            }
            {currentMessages.length === 0 &&
                <NoMessage pageType={props.pageType} />
            }
            <ReplyModal  isModalOpen ={replyModal} close={() => { setReplyModal(false); if(replyModal){ props.reRender() }} } attachmentValue={attachment} msg={selectedMessage} decryptedMessage={message} messageType={modalType} pageType={props.pageType}/>
        </>
    )
}

export default Messages
