import React, { useState, useEffect } from 'react';
import CommonMessage from "../components/CommonMessage";
import { web3AccountCheck } from '../helper/web3helper';
import hostContract from '../web3/hostContract.json';
import web3Config from '../web3/web3Config.json';
import { formatMessageList } from '../helper/dataFormatHelper';
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { getHostHelperContract } from "../helper/contract-helper.js";
const cookies = new Cookies();

function Archive() {
  const dispatch = useDispatch();
  const [user] = useState(cookies.get("userObject"));
  const [web3Value, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [web3Contract, setWeb3Contract] = useState('');
  const [allBoxList, setAllBoxList] = useState([]);
  const [readList, setReadList] = useState([]);
  const [unreadList, setUnreadList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rendered, setRendered] = useState(false);
  const [renderTime, setRenderTime] = useState(new Date());
  const userName = user.name;

  useEffect(() => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      web3AccountCheck(setWeb3, setAccount);
    } else {
      console.log('MetaMask is not installed');
    }
  }, []);

  useEffect(() => {
    if (account && web3Value) {
      const contractInstance = new web3Value.eth.Contract(hostContract.contract, web3Config.CONTRACT);
      setWeb3Contract(contractInstance);
    }
  }, [web3Value, account]);

  const getRefreshMessage = useSelector((state) => state.refreshMessage);
  useEffect(() => {
    if (web3Contract || getRefreshMessage) {
      setEmail();
    }
  }, [web3Contract, getRefreshMessage]);


  useEffect(() => {
    if (web3Contract) {
      setEmail();
    }
  }, []);


  async function setEmail(loaderRequired = true) {

    try {
        if(web3Contract) {
            if (loaderRequired) {
                setLoader(true);
                setRendered(false);
            }

            const emailList = await web3Contract.methods.getEmailList(userName).call({ from: account });
            const helperContract = getHostHelperContract();
            const attributes = await helperContract.methods.getAttributes(userName).call({ from: account });
            
            const formattedResult = emailList
                .filter(email => email.senderName)
                .map(email => ({
                id: parseInt(email.id),
                subject: email.subject,
                encryptedData: email.encryptedData,
                created_at: email.receivedDate,
                isStarred: email.isStarred,
                sender: email.senderName,
                isRead: email.isRead,
                header: email.emailDetails,
                isCC: email.isCC,
                isBCC: email.isBCC,
                attributes: attributes.filter(att => (att.attType == 'email' || att.key == 'isArchive') && parseInt(att.id) == parseInt(email.id))[0],
                ...email
                })).filter(data => data.attributes).filter(data => data.attributes.value);
            const { allList, readList, unreadList } = await formatMessageList(formattedResult.reverse(), "Archive");
            setAllBoxList(allList);
            setReadList(readList);
            setUnreadList(unreadList);

            if (loaderRequired) {
                setRendered(true);
            }
        }
    } catch (error) {
      console.log("error", error);
    }
  }
  const refreshCount = useSelector((state) => state.refreshCount);
  const reRender = () => {
    dispatch({ type: "REFRESH_COUNT", payload: !refreshCount });
    setEmail();
  }

  useEffect(() => {
    if (rendered) {
      setLoader(false)
    }
  }, [rendered]);


  return (
    <div className="inbox-container">
      <CommonMessage messageList={allBoxList} unreadList={unreadList} readList={readList} pageType={"Archive"} loader={loader} reRender={reRender} />
    </div>
  );
}

export default Archive;
